<template lang="">
    <div class="h-screen" ref="canvasContainer"></div>
</template>
<script setup>
import * as THREE from "three";
import { ref, onMounted } from "vue";
import { createRenderer } from "@vue/runtime-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as dat from "dat.gui";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const canvasContainer = ref(null);
const scene = new THREE.Scene();

const bg_color = new THREE.Color(0x14213d);
// scene.background = bg_color;

onMounted(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.gammaFactor = 2.2;
    renderer.outputEncoding = THREE.sRGBEncoding;

    renderer.setSize(
        canvasContainer.value.offsetWidth,
        canvasContainer.value.offsetHeight
    );

    // window.addEventListener("resize", () => {
    //     // update the size of the renderer
    //     renderer.setSize(window.innerWidth, window.innerHeight);

    //     // update the aspect ratio of the camera
    //     camera.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();
    // });

    // document.body.appendChild(renderer.domElement);
    const canvas = renderer.domElement;
    canvasContainer.value.appendChild(canvas);

    // setAxesGrid();

    setLight();

    const loader = new GLTFLoader();

    loader.load(
        "/models/train2.glb",
        function (gltf) {
            const model = gltf.scene;
            const train = model.getObjectByName("Sketchfab_model");

            // Get the camera from the glTF scene
            const blenderCamera = gltf.scene.getObjectByName("Camera");

            // Create a Three.js camera with the same properties as the Blender camera
            const camera = new THREE.PerspectiveCamera(
                75,
                window.innerWidth / window.innerHeight,
                blenderCamera.near,
                blenderCamera.far
            );

            // Set the position and rotation of the Three.js camera to match the Blender camera
            camera.position.copy(blenderCamera.position.multiplyScalar(0.07));
            camera.quaternion.copy(blenderCamera.quaternion);

            console.log(blenderCamera.position);

            // const orbit = new OrbitControls(camera, renderer.domElement);
            // orbit.enabled = false; // Disable the controls initially

            model.scale.set(0.1, 0.1, 0.1);

            // Usage: call setShadowProperties on your group
            setShadowProperties(model);

            const supporter = model.getObjectByName("supporter_cube");
            // const supporterMaterial = new THREE.MeshStandardMaterial({
            //     color: 0xffffff,
            //     metalness: 1,
            //     roughness: 0,
            // });

            const supporterMaterial = new THREE.MeshPhysicalMaterial({
                color: 0xfca311,
                opacity: 0.5,
                transparent: true,
                metalness: 0,
                roughness: 0.1,
                envMapIntensity: 0.5,
                refractionRatio: 0.98,
            });
            supporter.material = supporterMaterial;

            model.traverse(function (child) {
                if (child.name === "Object_27") {
                    const glassMaterial = new THREE.MeshPhysicalMaterial({
                        // color: 0xffffff,
                        color: 0xfca311,
                        transparent: true,
                        opacity: 0.5,
                        metalness: 0.5,
                        roughness: 0,
                        transmission: 0.9,
                        clearcoat: 1,
                        clearcoatRoughness: 0.1,
                        envMapIntensity: 1.5,
                    });
                    child.material = glassMaterial;
                }

                const seatBackMaterial = new THREE.MeshPhysicalMaterial({
                    color: 0x3d3d3d,
                    roughness: 0.3,
                    metalness: 0.1,
                    clearcoat: 0.5,
                    clearcoatRoughness: 0.5,
                    side: THREE.DoubleSide,
                });

                // create a physical material for the seat cushion
                const seatCushionMaterial = new THREE.MeshPhysicalMaterial({
                    color: 0xcccccc,
                    roughness: 0.5,
                    metalness: 0.1,
                    side: THREE.DoubleSide,
                });

                // seat back
                if (child.name === "Object_63") {
                    child.material = seatBackMaterial;
                }
            });

            // add scrolltrigger event
            gsap.registerPlugin(ScrollTrigger);
            const tl = gsap.timeline();
            tl.to(model.position, {
                x: 0.25,
                scrollTrigger: {
                    trigger: canvasContainer.value,
                    pin: true, // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=100", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                },
                // onComplete: () => {
                //     orbit.enabled = true; // Enable the controls once the animation is complete
                //     console.log(orbit.enabled);
                // },
            });

            tl.to(train.position, {
                z: 15,
                scrollTrigger: {
                    trigger: canvasContainer.value,
                    pin: true, // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=100", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                },
                // onComplete: () => {
                //     orbit.enabled = true; // Enable the controls once the animation is complete
                // },
            });

            scene.add(model);

            animate();

            function animate() {
                requestAnimationFrame(animate);
                // orbit.update();

                // Set up the tonemapping
                renderer.toneMapping = THREE.ReinhardToneMapping;
                renderer.toneMappingExposure = 1.75;

                renderer.render(scene, camera);
            }
        },
        undefined,
        function (error) {
            console.error(error);
        }
    );
});

function setDLightController(directionalLight) {
    const gui = new dat.GUI();
    const options = {
        dLightX: 2,
        dLightY: 5,
        dLightZ: 2,
    };

    gui.add(options, "dLightX").onChange(function (e) {
        directionalLight.position.set(
            options.dLightX,
            options.dLightY,
            options.dLightZ
        );
    });
    gui.add(options, "dLightY").onChange(function (e) {
        directionalLight.position.set(
            options.dLightX,
            options.dLightY,
            options.dLightZ
        );
    });
    gui.add(options, "dLightZ").onChange(function (e) {
        directionalLight.position.set(
            options.dLightX,
            options.dLightY,
            options.dLightZ
        );
    });
}

function setAxesGrid() {
    // grid helper
    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    scene.add(gridHelper);

    // axes helper
    const axesHelper = new THREE.AxesHelper(5);
    scene.add(axesHelper);
}

function setLight() {
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff);
    scene.add(directionalLight);

    directionalLight.position.set(10, 10, 10);
    directionalLight.castShadow = true;

    // set the shadow map size
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;

    // set the shadow camera properties
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 500;
    directionalLight.shadow.camera.left = -5;
    directionalLight.shadow.camera.right = 5;
    directionalLight.shadow.camera.top = 5;
    directionalLight.shadow.camera.bottom = -5;

    // Add helper for directional light shadow camera
    // const directionalLightCameraHelper = new THREE.CameraHelper(
    //     directionalLight.shadow.camera
    // );
    // scene.add(directionalLightCameraHelper);

    // const dLightHelper = new THREE.DirectionalLightHelper(directionalLight, 5);
    // scene.add(dLightHelper);

    setDLightController(directionalLight);

    const light = new THREE.PointLight(0xffffff, 1, 150);
    light.position.set(0.05, 0.05, 0);
    scene.add(light);

    // Create a helper for the light
    // const helper = new THREE.PointLightHelper(light, 1);
    // scene.add(helper);
}

function setShadowProperties(object) {
    object.traverse((child) => {
        if (child instanceof THREE.Group) {
            child.castShadow = true;
            child.receiveShadow = true;
        } else {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });
}
</script>
